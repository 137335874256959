import ModalError from "../Components/ModalError";
import errorImageScoreRecaptcha from "../Images/errorImageScoreRecaptcha.svg";
import iconHand from "../Images/IconHand.svg";

function ErrorDataModal({ redirectionHome }) {
    return (
        <ModalError
            isShowing={true}
            buttonClose={false}
            redirectFunction1={redirectionHome}
            title="El sistema no se encuentra disponible"
            message="Inténtalo nuevamente más tarde. Nuestro equipo está trabajando para brindarte una pronta solución."
            buttonText="Entendido"
            image={errorImageScoreRecaptcha}
            buttonBack={false}
        />
    );
}

function ErrorUnauthorizedModal({ redirectionHome }) {
    return (
        <ModalError
            isShowing={true}
            buttonClose={false}
            redirectFunction1={redirectionHome}
            title="Acceso no autorizado"
            message="Vuelve a ingresar e Inténtalo nuevamente"
            buttonText="Entendido"
            image={errorImageScoreRecaptcha}
            buttonBack={false}
        />
    );
}

function AlertCancelModal({ continueFlow, redirectCancelOperation }) {
    return (
        <ModalError
            isShowing={true}
            buttonClose={false}
            redirectFunction1={continueFlow}
            redirectFunction2={redirectCancelOperation}
            title="¿Qué deseas hacer?"
            message=""
            buttonText="Volver a biometría"
            buttonTextBack="Salir de biometría"
            image={iconHand}
            widthButton="300px"
            buttonBack={true}
        />
    );
}

function CameraErrorModal({ restartFlow, redirectCancelOperation, titleError, messageError, iconType, buttonBack, secondaryMessage }) {
    const errorIcon = iconType == 'error' ? errorImageScoreRecaptcha : iconHand;
    const buttonText = buttonBack ? 'Volver al inicio' : 'Entendido';
    const buttonFunction = buttonBack ? restartFlow : redirectCancelOperation;
    return (
        <ModalError
            isShowing={true}
            buttonClose={false}
            redirectFunction1={buttonFunction}
            redirectFunction2={redirectCancelOperation}
            title={titleError}
            message={messageError}
            buttonText={buttonText}
            buttonTextBack="Salir de biometría"
            image={errorIcon}
            widthButton="300px"
            buttonBack={buttonBack}
            secondaryMessage={secondaryMessage}
        />
    );
}

function RetryModal({ restartFlow, redirectCancelOperation, titleTries, messageTries }) {
    const errorTitleTries = `${titleTries} de nuevo`;
    const errorMessageTries = `Tuvimos problemas con tu ${messageTries}.`;
    return (
        <ModalError
            isShowing={true}
            buttonClose={false}
            redirectFunction1={restartFlow}
            redirectFunction2={redirectCancelOperation}
            title={errorTitleTries}
            message={errorMessageTries}
            buttonText="Intentar nuevamente"
            buttonTextBack="Salir"
            image={iconHand}
            widthButton="300px"
            buttonBack={true}
        />
    );
}

export function HandlerError({ machine, showAlertCancel, continueFlow, redirectCancelOperation, restartFlow, redirectionHome, titleError, messageError, iconType, buttonBack, secondaryMessage}) {
    const titleTries = machine.context.enrollment? "Regístrate" : "Autentícate";
    const messageTries = machine.context.enrollment? "registro" : "autenticación";
    return ( 
        <>
            {machine.matches("errorData") && (
                <ErrorDataModal redirectionHome={redirectionHome} />
            )}
            {machine.matches("unauthorized") && (
                <ErrorUnauthorizedModal redirectionHome={redirectionHome} />
            )}
            {showAlertCancel && (
                <AlertCancelModal continueFlow={continueFlow} redirectCancelOperation={redirectCancelOperation} />
            )}
            {machine.matches("cameraError") && (
                <CameraErrorModal restartFlow={restartFlow} redirectCancelOperation={redirectCancelOperation} titleError={titleError} messageError={messageError} iconType={iconType} buttonBack={buttonBack} secondaryMessage={secondaryMessage}/>
            )}
            {machine.matches("retry") && (
                <RetryModal restartFlow={restartFlow} redirectCancelOperation={redirectCancelOperation} titleTries={titleTries} messageTries={messageTries} />
            )}
        </>
    )
}