import styled from "styled-components";

export const ContainerRecaptcha = styled.div`
  width: fit-content;
  text-align: center;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: fit-content;
    top: 20px;
  }

  @media screen and (min-width: 280px) and (max-width: 961px) and (orientation: landscape) {
    height: 60px;
    top: 0px;
  }

  @media screen and (min-width: 320px) and (max-width: 850px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    height: 90px;
  }
`;
