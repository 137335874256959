import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

const thumbVerticalStyle = {
  backgroundColor: '#FF5733',
  cursor: 'pointer',
  borderRadius: 'inherit'
};

const thumbStyleTrack = {
  width: '4px',
  right: '2px',
  bottom: '2px',
  top: '2px',
  borderRadius: '3px',
  overflow: 'scroll'
};

function DynamicScrollbar(props) {

  const renderThumbVertical = (props, style) => {
    const thumbStyleWithColor  = { ...style, ...thumbVerticalStyle };
    return <div {...props} style={thumbStyleWithColor} />;
  };

  const renderTrackVertical = (props, style) => {
    const thumbStyleWidth = { ...style, ...thumbStyleTrack };
    return <div {...props} style={thumbStyleWidth} />;
  }

  return (
    <Scrollbars 
      universal
      autoHeight 
      autoHeightMax={415} 
      renderThumbVertical={renderThumbVertical}
      renderTrackVertical={renderTrackVertical}
    >
      {props.children}
    </Scrollbars>
  );
}

export default DynamicScrollbar;