import React from "react";
import styled from "styled-components";
import logoBanco from "../Images/BancolombiaLogo.svg"
import closeIcon from "../Images/closeIcon.svg"
import celphone from "../Images/Celphone.svg"
import face from "../Images/Face.svg"
import document from "../Images/Document.svg"
import Bancolombia from "../Images/Bancolombia.svg"
import SoyYo from "../Images/SoyYo.svg"
import TrazoContexto from "../Images/TrazoContexto.svg"
import {cancelOperation} from "../Constants/Constants"

const colors = {
  primary: "#FDDA24",
  secondary: "#2C2A29",
  white: "#ffffff",
};

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Open Sans";
  font-size: 15px;
  color: #454648;
`;
const Title = styled.div`
  display: flex;
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  font-family: "Nunito";
  text-align: center;
  margin-bottom: 36px;
  justify-content: center;

  @media screen and (min-width: 100px) and (max-width: 961px){
    font-size: 20px;
  }
`;

const Title2 = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: 700;
  color: #000000;
  font-family: "Open Sans";
  text-align: center;
  line-height: 20px;
  justify-content: center;

  @media screen and (min-width: 100px) and (max-width: 961px){
    font-size: 14px;
  }
`;

const Conditions = styled.div`
  justify-content: center;
  margin: 0.5rem 48px;
  text-align: start;
  margin-bottom: 36px;

  @media screen and (min-width: 481px) and (max-width: 961px) {
    margin: 13px 23px;
    width: auto;
  }

  @media screen and (min-width: 961px) {
    width: 90%;
  }
`;
const DescriptionText = styled.div`
  display: flex;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 17.7px;
  text-align: center;
  color: ${colors.secondary};
  width: 100%;
  height: auto;
  justify-content: start;
  text-align: start;

  @media screen and (min-width: 100px) and (max-width: 961px){
    font-size: 13px;
  }
`;
const SubTitle = styled.h1`
  display: flex;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  text-align: center;
  color: ${colors.secondary};
  width: 100%;
  height: auto;
  text-align: start;
  margin: 0px;
  justify-content: start;

  @media screen and (min-width: 100px) and (max-width: 961px){
    font-size: 16px;
    line-height: 17.31px;
  }
`;
const ImageLogo = styled.div`
  background-image: url(${logoBanco});
  background-repeat: no-repeat;
  background-position: center;
  width: 28px;
  height: 28px;
  margin-top: 29px;
  margin-bottom: 36px;
`;

const StepContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 30px;

    @media screen and (min-width: 961px) {
      margin: 3rem;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: start;
    align-items: center;
    width: 80%;
`;

const StepIcon = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  width: 29px;
  height: 29px;
  margin-right: 10px;
`;

const FirstStep = styled(StepIcon)`
  background-image: url(${celphone});
`;

const SecondStep = styled(StepIcon)`
  background-image: url(${face});
`;

const NumberThree = styled(StepIcon)`
  background-image: url(${document});
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 1rem;
  width: 80%;
  justify-content: center;
  align-items: center;
`;

const ButtonAcept = styled.button`
  background: ${colors.primary};
  color: #292929;
  border: 0px;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 50px;
  font-family: Nunito;
  font-size: 18px;
  outline: none;
  margin: 5px 0.5rem;

  @media screen and (min-width: 21px) and (max-width: 961px) {
    width: 90%;
  }
`;

const CloseButton = styled.div` 
  position: absolute; 
  width: 60px;
  height: 60px;
  top: 5px; 
  right: 5px; 
  background-image: url(${closeIcon});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  `;

  const TrazoContextoImg = styled.div` 
  position: absolute; 
  width: 120px;
  height: 100px;
  top: 0px; 
  right: 0px; 
  background-image: url(${TrazoContexto});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  `;

  const LogoContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 80%;
  justify-content: center;
  align-items: center;
  margin: 30px 0px;
`;

  const NumberOne = styled.div`
  background-image: url(${SoyYo});
  background-repeat: no-repeat;
  background-position: center;
  width: 84px;
  height: 28px;
  padding: 0px 15px;
`;

const NumberTwo = styled.div`
  background-image: url(${Bancolombia});
  background-repeat: no-repeat;
  background-position: center;
  width: 150px;
  height: 24px;
  padding: 0px 15px;
  border-right: solid 1px #B3B5B8;
`;

function close(cancel) {
  cancel(cancelOperation)
}

function Context({cancel, acept}) {

  const subTitle1 = "Entra desde tu teléfono móvil";
  const subTitle2 = "Prepárate para la foto"
  const subTitle3 = "Ten tu documento a la mano"
  const condition1 = "Debe ser tu dispositivo de confianza."
  const condition2 = "Registraremos tu cara automáticamente."
  const condition3 = "Es posible que te lo pidamos."

  const handleClose = React.useCallback(() => close(cancel))

  return (
    <Container>
      <TrazoContextoImg/>
      <CloseButton data-testid="close-button" onClick={handleClose}/>
      <ImageLogo/>
        <Conditions>
        <Title>Recomendaciones</Title>
          <StepContainer>
            <FirstStep/>
            <TextContainer>
                <SubTitle>{subTitle1}</SubTitle>
                <DescriptionText>{condition1}</DescriptionText>
            </TextContainer>
          </StepContainer>
          <StepContainer>
            <SecondStep/>
            <TextContainer>
                <SubTitle>{subTitle2}</SubTitle>
                <DescriptionText>{condition2}</DescriptionText>
            </TextContainer>
          </StepContainer>
          <StepContainer>
            <NumberThree/>
            <TextContainer>
                <SubTitle>{subTitle3}</SubTitle>
                <DescriptionText>{condition3}</DescriptionText>
            </TextContainer>
          </StepContainer>
          </Conditions>
        <Title2>Una vez inicies tu validación, debes finalizarla</Title2>
     <LogoContainer>
        <NumberOne/>
        <NumberTwo/>
      </LogoContainer>
      <ButtonContainer>
        <ButtonAcept onClick={acept}>Continuar</ButtonAcept>
      </ButtonContainer>
    </Container>
  );
}
export default Context;
