import styled from "styled-components";
import closeIcon from "../Images/closeIcon.svg";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(19.028px);
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
`;
export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CloseButton = styled.button`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #454648;
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
`;

export const Container = styled.div`
  display: flex;
  margin: 2rem auto;
  position: relative;
  flex-direction: column;
  align-content: center;
  width: 454px;
  top: 150px;
  background: #FFFFFF;
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  @media screen and (min-width: 340px) and (max-width: 480px) {
    width: 340px;
    height: ${props => props.heightM};
  }
  @media screen and (max-width: 340px) {
    width: 100%;
  }
`;

export const ImageClose = styled.div`
  background-image: url(${closeIcon});
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  padding: 5px;
`;

export const ImageError = styled.div`
  background-image: url(${props => props.image});
  display: flex;
  width: 48px;
  height: 80px;
  background-repeat: space;
`;

export const ContainerDes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
`;

export const ContainerButtons = styled.div`
  row-gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h4`
  color: #000000;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.45px;
  margin-bottom: 0px;
`;

export const Description = styled.div`
  color: #000000;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.3px;
`;

export const TitleMargin = styled.h4`
  margin-top: 5px;
  color: #000000;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.45px;
`;

export const DescriptionTiny = styled.div`
  max-width: 350px;
  color: #000000;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  letter-spacing: -0.3px;
  padding-bottom: 1em;
  margin: 0 2em;
`;

export const Button = styled.button`
  color: #292929;
  background: ${props => props.color || "#FDDA23"}; ;
  border: ${props => props.border || "0"};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.width || "auto"};
  height: 39px;
  border-radius: 100px;
  font-family: Nunito;
  font-size: 18px;
  outline: none;
  padding: 9px 41px;
  gap: 8px;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    left: 90px;
  }
`;
