import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import isotype from "../Images/Bancolombia.svg";
import guard from "../Images/vigilado.svg";

const color = "#000000";

const StyledFooter = styled.div`
  width: 95%;
  height: 0vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: transparent;
  margin: auto;
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 0 auto;
  background: #cccccc;
  border-radius: 4px;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 85%;
  }
`;

const CopyRight = styled.div`
  display: flex;
  align-items: center;
  color: ${color};
  font-family: "Open Sans";
  font-size: 17px;
  letter-spacing: -0.26px;
  line-height: 24px;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    justify-content: center;
    padding: 10px;
    width: 100%;
  }
`;

const IsoTypeImg = styled.div`
  margin-top: 10px;
  width: 140px;
  height: 17.98px;
  background-image: url(${isotype});
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  padding-right: 5px;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    margin: 10px auto auto auto;  
  }
`;
const GuardedImg = styled.div`
  height: 14px;
  background-image: url(${guard});
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  width: 115px;
  align-items: center;
  padding-right: 5px;
`;

const Span = styled.span`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    padding-top: 0%;
    height: 100%;
  }

  @media screen and (min-width: 481px) and (max-width: 768px) {
    padding-left: 5px;
    padding-right: 5px;
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    padding-right: 5px;
  }
`;

const Copy = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media only screen and (min-width: 280px) and (max-width: 480px) {
    align-items: center;
    padding-bottom: 15px;
    padding: 10px;
    width: 100%;
  }
`;
const Adress = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;
  
  @media screen and (min-width: 280px) and (max-width: 480px) {
    margin-left: 0;
    padding-bottom: 50px;
  }
`;

const Dates = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  color: ${color};
  font-family: "Open Sans";
  font-size: 17px;
  letter-spacing: -0.26px;
  line-height: 24px;
`;

const options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  hour12: "false",
};

const capitalize = s => typeof s === "string" ? s.charAt(0).toUpperCase() + s.slice(1): "";

const hourDate = new Date().toLocaleTimeString("en").split(":");
const dayMonthDate = new Date().toLocaleTimeString("es", options).split(" ");

const weekDayName = 0
const monthDayNumber = 1
const monthName = 3
const yearNumber = 5

const weekDay = capitalize(dayMonthDate[weekDayName]);
const monthDay = dayMonthDate[monthDayNumber];
const month = capitalize(dayMonthDate[monthName]);
const year = dayMonthDate[yearNumber];

const hourNumber = 0
const minutesNumber = 1
const excludeSecondsInit = 2
const excludeSecondsEnd = 3

const hour = hourDate[hourNumber];
const minutes = hourDate[minutesNumber];
const amPm = hourDate[excludeSecondsInit].slice(excludeSecondsEnd).toLowerCase();

const temolateString = `${weekDay} ${monthDay} de ${month} de ${year} ${hour}:${minutes} ${amPm}`;

function Footer({ top, topMovil }) {
  return (
    <StyledFooter top={top} topMovil={topMovil}>
      <Divider />
      <IsoTypeImg />
      <Span>
        <Copy>
          <CopyRight>Copyright © 2022 Grupo Bancolombia.</CopyRight>
          <GuardedImg />
        </Copy>
        <Adress>
          <Dates>{temolateString}</Dates>
        </Adress>
      </Span>
    </StyledFooter>
  );
}

Footer.propTypes = {
  top: PropTypes.string.isRequired,
  topMovil: PropTypes.string.isRequired,
};

export default Footer;
