import React from "react";
import styled from "styled-components";

import Bancolombia from "../Images/Bancolombia.svg"
import SoyYo from "../Images/SoyYo.svg"
import closeIcon from "../Images/closeIcon.svg"
import { soyYoTermsAndConditionsPolicies,cancelOperation, rejectTCSY} from "../Constants/Constants"

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Open Sans";
  font-size: 15px;
  color: #454648;
`;
const Title = styled.div`
  display: flex;
  font-size: 28px;
  font-weight: bold;
  color: #000000;
  font-family: "Nunito";
  text-align: center;

  @media screen and (min-width: 100px) and (max-width: 961px){
    font-size: 24px;
  }
`;
const Conditions = styled.div`
  width: auto;
  justify-content: center;
  margin: 61px 30px 30px;
  text-align: justify;
`;

const DescriptionText = styled.div`
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 22px;
  text-align: center;
  color: #2C2A29;
  width: auto;
  height: auto;
  text-align: start;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const NumberOne = styled.div`
  background-image: url(${SoyYo});
  background-repeat: no-repeat;
  background-position: center;
  width: 84px;
  height: 28px;
  padding: 0px 15px;
`;

const NumberTwo = styled.div`
  background-image: url(${Bancolombia});
  background-repeat: no-repeat;
  background-position: center;
  width: 150px;
  height: 24px;
  padding: 0px 15px;
  border-right: solid 1px #B3B5B8;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 80%;
  justify-content: center;
  align-items: center;
  margin: 30px 0px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 1rem;
  width: 80%;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 21px) and (max-width: 961px) {
    flex-direction: column;
  }
`;

const ButtonReject = styled.button`
  background: white;
  color: #292929;
  border: 1px solid #2C2A29;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 165px;
  height: 50px;
  font-family: Nunito;
  font-size: 18px;
  outline: none;
  margin: 5px 0.5rem;

  @media screen and (min-width: 21px) and (max-width: 961px) {
    width: 90%;
  }
`;

const ButtonAcept = styled.button`
  background-color: #FDDA24;
  color: #292929;
  border: 0px;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 163px;
  height: 50px;
  font-family: Nunito;
  font-size: 18px;
  outline: none;
  margin: 5px 0.5rem;

  @media screen and (min-width: 21px) and (max-width: 961px) {
    width: 90%;
  }
`;

const CloseButton = styled.div` 
  position: absolute; 
  width: 60px;
  height: 60px;
  top: 5px; 
  right: 5px; 
  background-image: url(${closeIcon});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  `;

  const BoldText = styled.span`
    font-weight: 700`;

  const Link = styled.a`
    font-weight: 700;`;

function reject(cancel) {
  cancel(rejectTCSY)
}

function close(cancel) {
  cancel(cancelOperation)
}

function TermsAndConditionsSoyYo({cancel, acept}) {
  
  const handleClose = React.useCallback(() => close(cancel))
  const handleReject = React.useCallback(() => reject(cancel))

  return (
    <Container>
      <CloseButton data-testid="close-button" onClick={handleClose}/>
      <Conditions>
        <Title>Términos y condiciones SoyYo</Title>
        <DescriptionText>Con el fin de crear mi identidad digital y prevenir el fraude por suplantación, autorizo a <BoldText>SoyYo</BoldText> a usar la foto de mi cara y a tratar mis datos de acuerdo con su <Link href={soyYoTermsAndConditionsPolicies} target="_blank">Política de privacidad y Términos y condiciones</Link>.
        </DescriptionText>
        <DescriptionText>Entiendo que esta aprobación no es obligatoria y que podré revocarla cuando yo quiera, pero sin ella no podré continuar el proceso.</DescriptionText>
      </Conditions>
      <LogoContainer>
        <NumberOne/>
        <NumberTwo/>
      </LogoContainer>
      <ButtonContainer>
        <ButtonAcept onClick={acept}>Aceptar</ButtonAcept>
        <ButtonReject onClick={handleReject}>Rechazar</ButtonReject>
      </ButtonContainer>
    </Container>
  );
}
export default TermsAndConditionsSoyYo;
