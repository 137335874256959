import React from "react";
import styled from "styled-components";

import logoBanco from "../Images/IconTermConditions.svg"
import closeIcon from "../Images/closeIcon.svg"

import number1 from "../Images/number1.svg"
import number2 from "../Images/number2.svg"
import number3 from "../Images/number3.svg"
import number4 from "../Images/number4.svg"
import number5 from "../Images/number5.svg"
import DynamicScrollbar from './DynamicScrollbar';
import {cancelOperation, rejectTC} from "../Constants/Constants"

const colors = {
  primary: "#FDDA24",
  secondary: "#2C2A29",
  white: "#ffffff",
};

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Open Sans";
  font-size: 15px;
  color: #454648;
`;
const Title = styled.div`
  display: flex;
  font-size: 28px;
  font-weight: bold;
  color: #000000;
  font-family: "Nunito";
  text-align: center;
  margin-bottom: 36px;

  @media screen and (min-width: 100px) and (max-width: 961px){
    font-size: 24px;
  }
`;
const Conditions = styled.div`
  width: auto;
  height: 415px;
  justify-content: center;
  margin: 0.5rem 30px;
  text-align: justify;

  @media screen and (min-width: 481px) and (max-width: 961px) {
    margin: 0.5rem 23px;;
  }
`;
const DescriptionText = styled.div`
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 22px;
  text-align: center;
  color: ${colors.secondary};
  width: auto;
  height: auto;
  text-align: start;
`;
const SubTitle = styled.h1`
  display: flex;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.3px;
  line-height: 22px;
  text-align: center;
  color: ${colors.secondary};
  width: auto;
  height: auto;
  justify-content: left;
  text-align: justify;
`;
const ImageLogo = styled.div`
  background-image: url(${logoBanco});
  background-repeat: no-repeat;
  background-position: center;
  width: 48px;
  height: 48px;
  margin-top: 49px;
  margin-bottom: 36px;
`;

const StepIcon = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 48px;
  margin-top: 49px;
  margin-bottom: 36px;
`;

const NumberOne = styled(StepIcon)`
  background-image: url(${number1});
`;

const NumberTwo = styled(StepIcon)`
  background-image: url(${number2});
`;

const NumberThree = styled(StepIcon)`
  background-image: url(${number3});
`;

const NumberFour = styled(StepIcon)`
  background-image: url(${number4});
`;

const NumberFive = styled(StepIcon)`
  background-image: url(${number5});
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 1rem;
  width: 80%;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 21px) and (max-width: 961px) {
    flex-direction: column;
  }
`;


const ButtonReject = styled.button`
  background: white;
  color: #292929;
  border: 1px solid ${colors.secondary};
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 165px;
  height: 50px;
  font-family: Nunito;
  font-size: 18px;
  outline: none;
  margin: 5px 0.5rem;

  @media screen and (min-width: 21px) and (max-width: 961px) {
    width: 90%;
  }
`;

const ButtonAcept = styled.button`
  background: ${colors.primary};
  color: #292929;
  border: 0px;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 163px;
  height: 50px;
  font-family: Nunito;
  font-size: 18px;
  outline: none;
  margin: 5px 0.5rem;

  @media screen and (min-width: 21px) and (max-width: 961px) {
    width: 90%;
  }
`;

const CloseButton = styled.div` 
  position: absolute; 
  width: 60px;
  height: 60px;
  top: 5px; 
  right: 5px; 
  background-image: url(${closeIcon});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  `;

const BoldText = styled.span`
  font-weight: 700`;

function reject(cancel) {
  cancel(rejectTC)
}

function close(cancel) {
  cancel(cancelOperation)
}

function TermsAndConditions({cancel, acept}) {

  const text3 = "En este proceso entregas información de carácter personal, que puede ser incluso sensible, por que debes tener en cuenta que autorizas al Grupo Bancolombia (Bancolombia S.A. y las entidades domiciliadas en Colombia y/o en el exterior, que pertenezcan o lleguen a pertenecer a su grupo empresarial, sus filiales y/o subsidiarias, al igual que Las Entidades en las que directa o indirectamente tenga o llegue a tener participación accionaria.) para que podamos tratar la información, es decir, solicitar, recolectar, consultar, almacenar, transmitir, transferir incluso a terceros países, compartir, actualizar, procesar, conservar y custodiar tus datos personales y aquellos de categorías especiales tales como biométricos, reconocimiento facial, dactilar, voice i.d, entre otros.";
  const text4 = "Esta información, tiene una protección especial por la Ley de Protección de Datos y considerado dato personal sensible por esto, no estás en la obligación de autorizar su tratamiento. Si no quieres usar la biometria facial, podrás usar otro método de autenticación para acceder a nuestros canales virtuales."
  const text5 = "El Banco se reserva el derecho a modificar unilateralmente, en cualquier momento y sin previo aviso, estos términos y condiciones, así como los productos y servicios allí habilitados, con el cumplimiento de las políticas de seguridad."
  const subTitle1 = "¿Qué pasa con tus datos personales?";
  const subTitle2 = "Ten en cuenta lo siguiente:"
  const subTitle3 = "¡Bienvenido(a)"
  const subTitle4 = "Equipo Bancolombia"
  const condition1 = "Tienes la posibilidad de revocar la autorización de tratamiento de datos biométricos en cualquier momento, a través de los canales que habilitemos o negarte a suministrarlos."
  const condition2 = "Bancolombia y SoyYo son Responsables del tratamiento de los datos personales. Por esto es importante que conozcas las políticas de privacidad y de tratamiento de datos de cada entidad."
  const condition3 = "Como titular de la información, te asisten los siguientes derechos, revocar la autorización en los casos que sea procedente, actualizar, rectificar e interponer peticiones, quejas o reclamos ante el Responsable de la información y la autoridad competente, todo según la Ley 1581 de 2012."
  const condition4 = "Siempre que utilices tus credenciales biométricas, vamos a dejar registro de la operación y por lo pronto, el proceso solo está habilitado para cédula de ciudadanía colombiana."
  const condition5 = "Tienes habilitado para la atención de reclamos y peticiones los siguientes canales: línea telefónica nacional 01 8000 912 345 y sucursales físicas."

  const handleClose = React.useCallback(() => close(cancel))
  const handleReject = React.useCallback(() => reject(cancel))

  return (
    <Container>
      <CloseButton data-testid="close-button" onClick={handleClose}/>
      <ImageLogo/>
      <DynamicScrollbar>
        <Conditions>
        <Title>Términos y condiciones Bancolombia</Title>
          <DescriptionText>Lee a continuación las reglas para activar el servicio de <BoldText>Biometría Facial</BoldText> en nuestros canales.</DescriptionText>
          <DescriptionText>El proceso de <BoldText>Biometría Facial</BoldText>  es desarrollado por nuestro aliado <BoldText>SoyYo</BoldText>. Por eso debes aceptar sus términos y condiciones que encontraras más adelante.</DescriptionText>
          <SubTitle>{subTitle1}</SubTitle>
          <DescriptionText>{text3}</DescriptionText>
          <DescriptionText>{text4}</DescriptionText>
          <SubTitle>{subTitle2}</SubTitle>
          <NumberOne/>
          <DescriptionText>{condition1}</DescriptionText>
          <NumberTwo/>
          <DescriptionText>{condition2}</DescriptionText>
          <NumberThree/>
          <DescriptionText>{condition3}</DescriptionText>
          <NumberFour/>
          <DescriptionText>{condition4}</DescriptionText>
          <NumberFive/>
          <DescriptionText>{condition5}</DescriptionText>
          <DescriptionText style={{marginTop: '34px'}}>{text5}</DescriptionText>
          <SubTitle style={{justifyContent: 'center'}}>{subTitle3}</SubTitle>
          <SubTitle style={{justifyContent: 'center'}}>{subTitle4}</SubTitle>
          </Conditions>
      </DynamicScrollbar>
      <ButtonContainer>
        <ButtonAcept onClick={acept}>Aceptar</ButtonAcept>
        <ButtonReject onClick={handleReject}>Rechazar</ButtonReject>
      </ButtonContainer>
    </Container>
  );
}
export default TermsAndConditions;
